<template>
	<div>
		<b-row>
			<b-colxx xxs="12">
				 <b-card class="mb-4" :title="$t('Usuarios')">
			        <b-table
			            ref="custom-table"
			            class="vuetable"
			            sort-by="title" sort-desc.sync="false"
			            @row-selected="rowSelected"
			            selectable
			            :select-mode="bootstrapTable.selectMode"
			            :current-page="currentPage"
			            selectedVariant="primary"
			            :fields="bootstrapTable.fields"
			            :items="dataArray"
			            :per-page="perPage"
			          >
			          <template slot="status" slot-scope="data">
			            <b-badge class="mb-1" :variant="data.item.statusColor">{{ data.value }}</b-badge>
			          </template>
			        </b-table>
			        <b-pagination
			            size="sm"
			            align="center"
			            :total-rows="totalRows"
			            :per-page="perPage"
			            v-model="currentPage"
			          >
			            <template v-slot:next-text>
			              <i class="simple-icon-arrow-right"/>
			            </template>
			            <template v-slot:prev-text>
			              <i class="simple-icon-arrow-left"/>
			            </template>
			            <template v-slot:first-text>
			              <i class="simple-icon-control-start"/>
			            </template>
			            <template v-slot:last-text>
			              <i class="simple-icon-control-end"/>
			            </template>
			          </b-pagination>


			      	</b-card>
			      	<b-card :title="$t('Información de usuario')" v-if="bootstrapTable.selected.length>0">
			      		
					    <b-colxx xxs="12">
					        
				          <b-form @submit.prevent="onGridFormSubmit">
				            <b-row>

				              <b-colxx sm="12">
				                <b-form-group :label="$t('Correo electrónico')">
				                  <b-form-input readonly type="email" v-model="selected.correo" />
				                </b-form-group>
				              </b-colxx>
				              <b-colxx sm="6">
				                <b-form-group :label="$t('Nombre')">
				                  <b-form-input readonly type="text" v-model="selected.nombre"/>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group :label="$t('Apellidos')">
				                  <b-form-input readonly v-model="selected.apellidos" ></b-form-input>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group :label="$t('Género')">
				                  <b-form-input readonly v-model="selected.genero" ></b-form-input>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group :label="$t('Teléfono')">
				                  <b-form-input readonly v-model="selected.telefono" ></b-form-input>
				                </b-form-group>
				              </b-colxx>
				              
				            </b-row>

				            <!--<b-button type="button" variant="primary" class="mt-4">{{ $t('forms.signin') }}</b-button>-->

				            <!--<b-button type="button" variant="primary" class="mt-4">Guardar cambios</b-button>-->


				          </b-form>
					    </b-colxx>
  
			      	</b-card>
			</b-colxx>
		</b-row>
	</div>
</template>
<script>


import { request, mapActions} from 'vuex';

	export default{
		data(){
			return{
				dataArray: [],
			    bootstrapTable: {
			        selected: [],
			        selectMode: 'single',
			        fields: [
			          	{ key: 'id', label: 'Id', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'nombre', label: 'Nombre', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'apellidos', label: 'Apellidos', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'correo', label: 'Correo electrónico', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'fechaCreacion', label: 'Fecha', sortable: true, tdClass: 'text-muted', formatter:  function(valueFecha){
												return new Date(valueFecha).toLocaleString("es-MX");
											}
						},
			          	{ key: 'estado', label: 'Estado', sortable: true, tdClass: 'text-muted' },
			        ]
			    },
			    currentPage: 1,
			    perPage: 5,
			    
			}
		},

		computed: {
			totalRows(){
				return this.dataArray.length;
			},
			selected(){
				return this.bootstrapTable.selected[0];
			}
		},

		mounted: function(){
			this.getUsuarios();
		},

		methods:{
			...mapActions(["getAll","getOne", "create", "update", "delete"]),

			getUsuarios () {
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		    	var instance = this;

		    	let payload = {
		    		endpoint: "/api/usuario",
		    		token: token
		    	}

		    	this.getAll(payload).then(response => {
		    		instance.dataArray = response.data.data;
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },

		    formatoFecha:function(valueFecha){
				return new Date(valueFecha).toLocaleString("es-MX");
			},
		    
		    onPaginationData (paginationData) {
		      this.$refs.pagination.setPaginationData(paginationData)
		    },
		    onChangePage (page) {
		      this.$refs.vuetable.changePage(page)
		    },
		    rowSelected (items) {
		      this.bootstrapTable.selected = items
		    },
		}
	}
</script>